/**
 * This file defines the AddDates component.
 * It is used for the maintainer to add new dates for the community.
 */

import React, {Component} from 'react';
import {Accordion, Button, Collapse, Form} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import {Dropdown} from 'semantic-ui-react';
import Swal from 'sweetalert2'; //Pop-Up
import withReactContent from 'sweetalert2-react-content';

import {Capitalize, HolyDayToString, SendFormToServer, ErrorPopUp,
        HOLY_DAY_TYPES, GLOBAL_ERROR_MESSAGES} from '../../Util';
import {RenderDatePickerHeader} from '../BirthdayField';

import {BiSolidTrash} from "react-icons/bi";

import "react-datepicker/dist/react-datepicker.css";
import "../../styles/MaintainerAddDates.css";

//TODO: Set sign-up deadline and Erev Shabbat/Chag

const ReactPopUp = withReactContent(Swal);
const MEAL_OPTIONS = ["lunch", "dinner"].map(
    (type, i) => ({key: i, text: Capitalize(type), value: type})
);


const ERROR_MESSAGES =
{
    ...GLOBAL_ERROR_MESSAGES,
    INVALID_PASSWORD: "Unauthorized access!",
    INVALID_DATES: "One or more of the dates you entered is invalid.",
    WEEK_ALREADY_EXISTS: "One or more of the dates you entered already exists in the database.",
    NOT_SHABBAT: "One or more of the dates you entered is not on Shabbat.",
    NOT_HOLIDAY: "One or more of the dates you entered is not a holiday.",
    DATE_PASSED: "One or more of the dates you entered has already passed.",
    NULL_DATES: "The dates to add were wiped before reaching the server!",
};


export class AddDates extends Component
{
    /**
     * Represents the AddDates component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {Object} props.locationDetails - The location details of the community.
     * @param {Function} props.reloadData - The function to reload the data from the server.
     */
    constructor(props)
    {
        super(props);

        //Set the default date for the add dates section
        let defaultDate = new Date();
        defaultDate.setHours(0, 0, 0, 0);
        if (defaultDate.getDay() !== 6) //If today is not Saturday
            defaultDate.setDate(defaultDate.getDate() + (6 - defaultDate.getDay())); //The default date is the upcoming Saturday
        else
            defaultDate.setDate(defaultDate.getDate() + 7); //If today is Saturday, go to next Saturday
        
        this.state =
        {
            dates: [defaultDate, null],
            holyDays: ["Shabbat", "Shabbat"],
            meals: ["lunch", "lunch"],
            useFallbackHosts: [true, true],
            forceHolidayDates: [false, false],
            signUpDeadlines: ["", ""],
            erevs: ["", ""],
            showAdvancedOptions: [false, false],
            locationDetails: props.locationDetails,
        };

        this.reloadData = props.reloadData;
    }

    /**
     * Adds a new date to the state.
     * @param {number} i - The index of the date to add.
     * @param {Date} date - The date to add.
     */
    addDateToState(i, date)
    {
        //Update state
        let dates = this.state.dates;
        let meals = this.state.meals;
        let holyDays = this.state.holyDays;
        let useFallbackHosts = this.state.useFallbackHosts;
        let showAdvancedOptions = this.state.showAdvancedOptions;
        let forceHolidayDates = this.state.forceHolidayDates;
        let signUpDeadlines = this.state.signUpDeadlines;
        let erevs = this.state.erevs;
        dates[i] = date;

        //Add a new date if the last date is filled
        if (i + 1 >= this.state.dates.length)
        {
            dates.push("");
            meals.push("lunch");
            holyDays.push("Shabbat");
            useFallbackHosts.push(true);
            showAdvancedOptions.push(false);
            forceHolidayDates.push(false);
            signUpDeadlines.push("");
            erevs.push("");
        }

        this.setState
        ({
            dates: dates,
            meals: meals,
            holyDays: holyDays,
            useFallbackHosts: useFallbackHosts,
            showAdvancedOptions: showAdvancedOptions,
            forceHolidayDates: forceHolidayDates,
            signUpDeadlines: signUpDeadlines,
            erevs: erevs,
        });
    }

    /**
     * Removes a date from the state.
     * @param {number} i - The index of the date to remove.
     */
    removeDateFromState(i)
    {
        if (this.state.dates.length <= 1)
            return; //Always keep at least one date

        //Update state
        let dates = this.state.dates;
        let meals = this.state.meals;
        let holyDays = this.state.holyDays;
        let useFallbackHosts = this.state.useFallbackHosts;
        let showAdvancedOptions = this.state.showAdvancedOptions;
        let forceHolidayDates = this.state.forceHolidayDates;
        let signUpDeadlines = this.state.signUpDeadlines;
        let erevs = this.state.erevs;
        dates.splice(i, 1);
        meals.splice(i, 1);
        holyDays.splice(i, 1);
        useFallbackHosts.splice(i, 1);
        showAdvancedOptions.splice(i, 1);
        forceHolidayDates.splice(i, 1);
        signUpDeadlines.splice(i, 1);
        erevs.splice(i, 1);

        this.setState
        ({
            dates: dates,
            meals: meals,
            holyDays: holyDays,
            useFallbackHosts: useFallbackHosts,
            showAdvancedOptions: showAdvancedOptions,
            forceHolidayDates: forceHolidayDates,
            signUpDeadlines: signUpDeadlines,
            erevs: erevs,
        });
    }

    /**
     * Adds new dates to the database.
     */
    async saveDates()
    {
        //Zip together the dates holy day types, and meals
        let holyDays = this.state.holyDays;
        let meals = this.state.meals;
        let useFallbackHosts = this.state.useFallbackHosts;
        let forceHolidayDates = this.state.forceHolidayDates;
        let data = this.state.dates.map(function(date, i) {
            return {date: date, type: holyDays[i], meal: meals[i], 
                    useFallbackHost: useFallbackHosts[i], forceHolidayDate: forceHolidayDates[i]};
        });

        //Remove any empty dates
        data = data.filter(function(date) {
            return date.date !== "" && date.date != null;
        });

        //Sort dates by date and if they're the same then put lunch meals first
        data.sort(function(a, b) {
            if (a.date === b.date)
                return a.meal === "lunch" ? -1 : 1;
            return a.date < b.date ? -1 : 1;
        });

        //Convert all to format YYYY-MM-DD
        for (let date of data)
            date.date = date.date.toLocaleDateString("en-CA");

        //Send the data to the server
        let success = await SendFormToServer({dates: data}, this, "/addweekstodatabase", 'Dates were added successfully!',
                                              this.state.locationDetails, null);

                                              
        //Reload the data if successful but wait for the pop-up to close first
        while (success && ReactPopUp.isVisible())
            await new Promise(resolve => setTimeout(resolve, 100));

        if (success)
            this.reloadData();
    }

    /**
     * Displays an error pop-up.
     * @param {*} errorSymbol - The error symbol for the message to be shown on the pop-up.
     */
    errorPopUp(errorSymbol)
    {
        let text = (errorSymbol in ERROR_MESSAGES) ?  ERROR_MESSAGES[errorSymbol] : errorSymbol;
        ErrorPopUp(text);
    }

    /**
     * Prints the fields to add a new date.
     * @param {number} i - The index of the fields.
     * @returns {JSX.Element} The rendered fields to add a new date.
     */
    printDateSelection(i)
    {
        const holyDayOptions = Object.keys(HOLY_DAY_TYPES).map(
            (type, i) => ({key: i, text: HolyDayToString(type), value: type})
        ); //Can't be defined in the outer scope because the tests fail due to being unable to import HOLY_DAY_TYPES

        return (
            <div className="maintainer-date-input-container"> {/* Centre horizontally */}
                <div className="d-flex flex-column mb-3" key={i}> {/* Put the advanced options below the date picker */}
                    <div className="maintainer-date-input-container"> {/* Restore the centre horizontally */}
                        {/* Remove Button */}
                        <Button variant="danger" className="add-dates-remove-date-button"
                                onClick={this.removeDateFromState.bind(this, i)}>
                            <BiSolidTrash size={20} />
                        </Button>

                        {/* Date */}
                        <DatePicker
                            className="react-datepicker"
                            dateFormat={"MMMM d, yyyy" /* Show readable date */}
                            selected={this.state.dates[i]}
                            renderCustomHeader={RenderDatePickerHeader}
                            minDate={new Date()}
                            maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                            showDisabledMonthNavigation
                            onChange={this.addDateToState.bind(this, i)}/>

                        {/* Holy Day Type */}
                        <Dropdown
                            className="maintainer-holy-day-dropdown"
                            fluid
                            search
                            selection
                            options={holyDayOptions}
                            value={this.state.holyDays[i]}
                            onChange={(_, data) =>
                                {
                                    let holyDays = this.state.holyDays;
                                    holyDays[i] = data.value;
                                    this.setState({holyDays: holyDays});
                                }} />

                        {/* Lunch/Dinner */}
                        <Dropdown
                            className="maintainer-lunch-dinner-dropdown"
                            fluid
                            search
                            selection
                            options={MEAL_OPTIONS}
                            value={this.state.meals[i]}
                            onChange={(_, data) =>
                                {
                                    let meals = this.state.meals;
                                    meals[i] = data.value;
                                    this.setState({meals: meals});
                                }} />
                    </div>

                    {/* Advanced Options */}
                    {this.printAdvancedOptions(i)}
                </div>
            </div>
        )
    }

    /**
     * Prints the advanced options toggle for a date.
     * @param {number} i - The index of the date.
     * @returns {JSX.Element} The rendered advanced options toggle for a date.
     */
    printAdvancedOptions(i)
    {
        let visible = this.state.showAdvancedOptions[i];
        const collapseFunc = (i) =>
        {
            let showAdvancedOptions = this.state.showAdvancedOptions;
            showAdvancedOptions[i] = !showAdvancedOptions[i];
            this.setState({showAdvancedOptions: showAdvancedOptions});
        };

        //Print expandable div
        return (
            <div key={i} className={"add-dates-more-options-container" + (visible ? " border-bottom" : "")}>
                {/* Toggle */}
                <small className="link-text no-select"
                        onClick={() => collapseFunc(i)}
                        aria-controls={`collapse-text-date-${i}`}
                        aria-expanded={visible} >
                {
                    !visible ?
                        "More Options ►"
                    :
                        "Less Options ▲"
                }
                </small>

                {/* Actual Content */}
                <Collapse in={visible}>
                    <div className="add-dates-more-options-hidden-container"
                        id={`advanced-options-collapse-${i}`}>
                        {/* Checkbox for using fallback host */}
                        <Form.Check
                            type="checkbox"
                            label="Use Fallback Host"
                            checked={this.state.useFallbackHosts[i]}
                            onChange={(e) =>
                            {
                                let useFallbackHosts = this.state.useFallbackHosts;
                                useFallbackHosts[i] = e.target.checked;
                                this.setState({useFallbackHosts: useFallbackHosts});
                            }} />

                        {/* Checkbox for forcing holiday date */}
                        {
                            this.state.holyDays[i] !== "Shabbat" &&
                                <Form.Check
                                    type="checkbox"
                                    label="Force Holiday Date"
                                    onChange={(e) =>
                                    {
                                        let forceHolidayDates = this.state.forceHolidayDates;
                                        forceHolidayDates[i] = e.target.checked;
                                        this.setState({forceHolidayDates: forceHolidayDates});
                                    }} />
                        }

                        {/* Sign-up deadline */}
                        {/* TODO */}

                        {/* Erev Shabbat/Chag */}
                        {/* TODO */}
                    </div>
                </Collapse>
            </div>
        );
    }

    /**
     * Renders the AddDates component.
     * @returns {JSX.Element} The rendered AddDates component.
     */
    render()
    {
        return (
            <>
                <Accordion.Header>Add Dates</Accordion.Header>
                <Accordion.Body className="maintainer-date-addition maintainer-main-accordian-body">
                    {/* Date List */}
                    {
                        this.state.dates.map((_, i) =>
                        {
                            return this.printDateSelection(i);
                        })
                    }

                    {/* Save Button */}
                    <Button variant="success" className="maintainer-date-addition-button"
                            onClick={this.saveDates.bind(this)}>
                        Add Dates
                    </Button>
                </Accordion.Body>
            </>
        );
    }
}

export default AddDates;
