/**
 * This file defines the MessageHostButton component.
 * It is used for the maintainer to send a WhatsApp message asking if a host wants to host a meal.
 */

import React, {Component} from 'react';
import {Button} from "react-bootstrap";

import {FormatWhatsAppPhoneNumber, WhatsAppUrl} from "../PhoneNumberField";
import {CalculateDaysSinceLastHosted} from "../../MaintainerView";
import {HolyDayToString} from "../../Util";

import {MdWhatsapp} from "react-icons/md";


export class MessageHostButton extends Component
{
    /**
     * Represents the MessageHostButton component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {Object} props.host - The host to message.
     * @param {Object} props.upcomingWeek - The upcoming week to ask the host about.
     * @param {Number} props.missingSpots - The number of people still needing a meal for the upcoming week.
     * @param {Object} props.locationDetails - The location details for the current community.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            host: props.host,
            upcomingWeek: props.upcomingWeek,
            missingSpots: props.missingSpots || 0,
            locationDetails: props.locationDetails,
        };
    }

    /**
     * Checks if the host has hosted a meal in this community recently.
     * @returns {boolean} Whether the host has hosted recently.
     */
    hostedRecently()
    {
        return CalculateDaysSinceLastHosted(this.state.host) <= 30; //Hosted in the last month
    }

    /**
     * Gets the message to send to the host.
     * @returns {string} The message to send to the host.
     */
    getMessageToSend()
    {
        const locationDetails = this.state.locationDetails;

        //Start with the preset greeting
        let message = `${this.state.host.greeting} `;
    
        //Add a line if they haven't hosted in a while
        if (!this.hostedRecently())
            message += `I see you haven't hosted a Shlabot meal in a while. Would `;
        else
            message += `would `;

        //Get the type of people to host
        let people = `${locationDetails.community} ${locationDetails.guestType}s`;

        //Ask for the upcoming week if there is one
        let upcomingWeek = this.state.upcomingWeek;
        if (upcomingWeek != null)
        {
            //Determine whether to ask for "this week" or a specific date
            let askForDate = "";
            let upcomingDate = new Date(upcomingWeek.date);
            let today = new Date();
            let daysUntil = Math.ceil((upcomingDate - today) / (1000 * 60 * 60 * 24));

            if (upcomingWeek.type === "Shabbat" //For holidays never say "this week" because two-day holidays are ambiguous
            && daysUntil >= 1 && daysUntil <= 6 //At most 6 because Saturday is 6 days after Sunday
            && upcomingDate.getDay() > today.getDay()) //This week specifically (and not next week)
                askForDate = "this week";
            else //Format date to be readable like "December 24, 2020"
                askForDate = `on ${upcomingDate.toLocaleDateString('en-CA', {month: 'long', day: 'numeric', year: 'numeric'})}`;

            message += `you like to host some ${people} for ${HolyDayToString(upcomingWeek.type)} ${upcomingWeek.meal} ${askForDate}? `;

            //Mention how many people still need a meal
            if (this.state.missingSpots > 0)
            {
                let num = this.state.missingSpots;
                let are = (num === 1) ? "is" : "are";
                let people = locationDetails.guestType;
                let need = (num === 1) ? "needs" : "need";
                if (num !== 1)
                    people += "s";

                message += `There ${are} still ${num} ${people} who ${need} a meal. `;
            }
        }
        else
            message += `you like to host a meal for ${people} soon? `;

        return message.trim();
    }

    /**
     * Opens WhatsApp with a message to the host.
     */
    sendMessage()
    {
        //Get the phone number of the host
        let phoneNumber = FormatWhatsAppPhoneNumber(this.state.host.phone, this.state.locationDetails.countryCode);

        //Open WhatsApp with the host's phone number
        const baseUrl = WhatsAppUrl();
        const paramSymbol = (baseUrl.includes("?")) ? "&" : "?"; //In the shortened link, the text param starts with ? because phone number is passed in without it
        const url = `${WhatsAppUrl()}${phoneNumber}${paramSymbol}text=${this.getMessageToSend()}`;
        const newTab = window.open(url, '_blank');
        if (newTab)
            newTab.focus(); //Switch to the new tab
    }

    /**
     * Renders the MessageHostButton component.
     * @returns {JSX.Element} The rendered MessageHostButton component.
     */
    render()
    {
        return (
            <Button variant="success" onClick={this.sendMessage.bind(this)}>
                <MdWhatsapp size={20} data-testid="whatsapp-icon" />
            </Button>
        );
    }
}

export default MessageHostButton;
