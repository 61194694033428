/**
 * This file defines the RadioOptions component.
 * It is used to select choices from a radio group.
 */

import React, {Component} from 'react';
import {Card, Form} from "react-bootstrap";

import {RequiredTooltip} from '../Util';


export class RadioOptions extends Component
{
    /**
     * Represents the RadioOptions component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {string} props.input - The default input value.
     * @param {string[]} props.radioLabels - The labels for the radio buttons.
     * @param {string} props.fieldLabel - The label for the input.
     * @param {string} props.fieldDesc - The description for the input.
     * @param {JSX.Element} props.warningCardText - The text to display in a warning card.
     * @param {boolean} props.required - Whether the input is required.
     * @param {Function} props.setParentInput - The function to set the parent input.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            input: props.input,
            radioLabels: props.radioLabels,
            fieldLabel: props.fieldLabel || "",
            fieldDesc: props.fieldDesc || "",
            warningCardText: props.warningCardText || "",
            required: props.required || false,
        }

        this.setParentInput = props.setParentInput;
    }

    /**
     * Sets the input value and updates the parent component.
     * @param {Boolean} val - The new input value.
     */
    setInput(val)
    {
        this.setState({input: val});
        this.setParentInput(val);
    }

    /**
     * Renders the RadioOptions component.
     * @returns {JSX.Element} The rendered RadioOptions component.
     */
    render()
    {
        const fieldLabelId = this.state.fieldLabel.toLowerCase().replaceAll(" ", "-");
        const required = this.props.required ? RequiredTooltip() : "";
        const radioButtons = this.state.radioLabels.map((data, i) =>
        {
            let {label, val} = data;

            return (
                <Form.Check
                    key={i}
                    required
                    inline
                    type="radio"
                    id={"radio-" + label.toLowerCase().replaceAll(" ", "-")}
                    data-testid={label.toLowerCase().replaceAll(" ", "-") + "-radio-input"}
                    label={label}
                    name={fieldLabelId}
                    checked={this.state.input === val}
                    onChange={() => this.setInput(val)}
                />
            );
        });

        return (
            <Form.Group className="mb-3">
                {/* Title */}
                <Form.Label className={this.state.fieldDesc ? "mb-0" : "mb-1"}>{this.state.fieldLabel}{required}</Form.Label>

                {/* Note */}
                {
                    this.state.fieldDesc &&
                        <div className="mb-1">
                            <Form.Text className="text-muted">
                                {this.state.fieldDesc}
                            </Form.Text>
                        </div>
                }

                {/* Buttons */}
                <div key={`${fieldLabelId}-radio`}>
                    {radioButtons}
                </div>

                {/* Warning */}
                <div className="mb-1 mt-2">
                {
                    this.state.warningCardText !== "" &&
                        <Card bg="warning">
                            <Card.Body>
                                {this.state.warningCardText}
                            </Card.Body>
                        </Card>
                }
                </div>
            </Form.Group>
        );
    }
}

export default RadioOptions;
