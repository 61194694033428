/**
 * A class for people to donate to the Shlabot admin.
 */

import React from "react";
import {Button, Form} from "react-bootstrap";
import Twemoji from 'react-twemoji';
import {Dropdown} from "semantic-ui-react";

import {ProcessTextInput, RequiredTooltip} from "./Util";

import "./styles/Donate.css";

const PAYPAL_LOGO = "https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg";
const BIT_LOGO = "https://upload.wikimedia.org/wikipedia/he/e/eb/Bit_logo_2024.svg";
const PAYBOX_LOGO = "https://www.payboxapp.com/wp-content/uploads/2024/08/pay-box-logo@3x.webp";

const PAYPAL_BASE_URL = "https://www.paypal.me/shlabot";
const BIT_BASE_URL = "https://www.bitpay.co.il/app/me/56349E4A-D226-C06D-7E92-3F3D5AC3C02EADF4";
const PAYBOX_BASE_URL = "https://payboxapp.page.link/zUBxnUTawXHXeS9T6";

const BIT_PHONE_NUMBER = "0542892190";

const CURRENCIES = {
    "NIS": {countryCode: "IL", symbol: "₪", defaultAmount: 72},
    "CAD": {countryCode: "CA", symbol: "$", defaultAmount: 30},
    "USD": {countryCode: "US", symbol: "$", defaultAmount: 20},
    "AUD": {countryCode: "AU", symbol: "$", defaultAmount: 32},
    "GBP": {countryCode: "GB", symbol: "£", defaultAmount: 18},
    "EUR": {countryCode: "EU", symbol: "€", defaultAmount: 20},
    "OTHER": {countryCode: "NONE", symbol: "", defaultAmount: ""},
}

/**
 * Renders a flag emoji.
 * @param {Object} props - The properties object.
 * @param {string} props.countryCode - The country code used to generate the flag emoji.
 * @returns {JSX.Element} The Twemoji component containing the flag emoji.
 */
const FlagSymbol = (props) =>
{
    return (
        <Twemoji key={props.countryCode} options={{className: "donate-flag-symbol"}}>
            {
                props.countryCode === "NONE" ? //No country code
                    "◻️" //Pretend it's a white flag
                :
                    GetFlagEmoji(props.countryCode)
            }
        </Twemoji>
    );
}

// Map over CURRENCIES to create the options for the dropdown
const CURRENCY_OPTIONS = Object.keys(CURRENCIES).map(currency =>
{
    return {
        key: currency,
        text: <span className="d-flex"><FlagSymbol countryCode={CURRENCIES[currency].countryCode} /> <p>{currency} {CURRENCIES[currency].symbol}</p></span>,
        value: currency,
    }
});


class Donate extends React.Component
{
    /**
     * Sets up the donate page.
     * @param {Object} props - The props including router data.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            currencyInput: CURRENCY_OPTIONS[0].value,
            amountInput: CURRENCIES[CURRENCY_OPTIONS[0].value].defaultAmount,
            nameInput: "",
            messageInput: "",
        };
    }

    /**
     * Sets the currency being donated,
     * @param {string} - The selected currency.
     */
    setCurrency(newValue)
    {
        this.setState
        ({
            currencyInput: newValue,
            amountInput: CURRENCIES[newValue].defaultAmount, //Reset the default amount for the new currency
        });
    }

    /**
     * Sets the amount of money being donated.
     * @param {Object} e - The event object to get the input from.
     */
    setAmount(e)
    {
        let value = Math.max(0, parseInt(e.target.value)); //Number input
        this.setState({amountInput: value});  
    }

    /**
     * Sets the name of the person donating.
     * @param {Object} e - The event object to get the input from.
     */
    setName(e)
    {
        let name = ProcessTextInput(e, "NAME", false);
        this.setState({nameInput: name});
    }

    /**
     * Sets the optional personal message from the person donating.
     * @param {Object} e - The event object to get the input from.
     */
    setMessage(e)
    {
        let message = ProcessTextInput(e, "MESSAGE", false);
        this.setState({messageInput: message});
    }

    /**
     * Opens a link in a new tab.
     * @param {string} link - The link to open.
     */
    openLink(link)
    {
        window.open(link, "_blank");
    }

    /**
     * Prints the PayPal donation button.
     * @returns {JSX.Element} A button that links to PayPal.
     */
    printPaypalDonationOption()
    {
        return (
            <Button className="donate-payment-option" variant="warning" //Warning because a PayPal button is usually yellow
                //Value can be passed in, but when no other links can support it, comment it out here to sync with the other buttons
                onClick={this.openLink.bind(this, `${PAYPAL_BASE_URL}`)}> {/* /${this.state.amountInput}${this.state.currencyInput}`)}> */}
                <img src={PAYPAL_LOGO} alt="PayPal" />
            </Button>
        );
    }

    /**
     * Prints the donation buttons for Israel.
     * @returns {JSX.Element} Buttons that link to Israeli donation methods.
     */
    printIsraeliDonationOptions()
    {
        return (
            <div className="donate-payment-options">
                {/* Paypal */}
                {this.printPaypalDonationOption()}

                {/* Bit */}
                <Button className="donate-payment-option d-flex justify-content-center"
                        //Currently no way to enter preset amount :(
                        onClick={this.openLink.bind(this, `${BIT_BASE_URL}`)}>
                    <img src={BIT_LOGO} alt="Bit" style={{marginRight: "5px"}} />
                    <p>{BIT_PHONE_NUMBER}</p>
                </Button>

                {/* Paybox */}
                <Button className="donate-payment-option" variant="info"
                        //Curently no way to enter preset amount :(
                        onClick={this.openLink.bind(this, `${PAYBOX_BASE_URL}`)}>
                    <img src={PAYBOX_LOGO} alt="PayBox" />
                </Button>
            </div>
        );
    }

    /**
     * Prints the donation buttons for countries other than Israel.
     * @returns {JSX.Element} Buttons that link to the default donation methods.
     */
    printDefaultDonationOptions()
    {
        return (
            <div className="donate-payment-options">
                {/* Paypal */}
                {this.printPaypalDonationOption()}
            </div>
        )
    }

    /**
     * Prints the donation buttons.
     * @returns {JSX.Elements} Buttons that link to donation methods.
     */
    printDonateButtons()
    {
        switch (this.state.currencyInput)
        {
            case "NIS":
                return this.printIsraeliDonationOptions();
            default:
                return this.printDefaultDonationOptions();
        }
    }

    /**
     * Prints the donate page.
     * @returns {JSX.Element} 
     */
    render()
    {        
        const required = RequiredTooltip();

        return (
            <div className="form-page">
                {/* Intro Blurb */}
                <h1 className="form-title">Your Generosity Matters!</h1>
                <p className="donate-description">
                    Thank you for choosing to donate to <span className="shla">SHLA</span><span className="bot">BOT</span>!
                    <br/>
                    Your support is invaluable and helps us continue our mission of keeping the site running smoothly for everyone.
                </p>
                <p className="text-muted text-center">
                    At this time, we are unable to provide tax receipts for donations.
                </p>

                {/* Donation Form */}
                <Form>
                    <Form.Group className="mb-3" controlId={"formBasicAmount"}>
                        <Form.Label>Currency{required}</Form.Label>
                        <div className="d-flex">
                            {/* Currency */}
                            <Dropdown
                                className="donate-currency-dropdown"
                                fluid
                                selection
                                options={CURRENCY_OPTIONS}
                                value={this.state.currencyInput}
                                onChange={(_, data) => this.setCurrency(data.value)} />

                            {/* Amount */}
                            <Form.Control className="donate-amount"
                                //type="number"
                                //min={1}
                                readOnly
                                disabled
                                value={
                                    this.state.amountInput ?
                                        `Suggested: ${this.state.amountInput}`
                                    :
                                        ""
                                }
                                //onChange={(e) => this.setAmount(e)}
                            />
                        </div>
                    </Form.Group>

                    {/* Name */}
                    {/* <Form.Group className="mb-3" controlId={"formBasicName"}>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            name="name"
                            data-testid="name-input"
                            placeholder="Anonymous"
                            value={this.state.nameInput}
                            className={`form-control`}
                            onChange={(e) => this.setName(e)}
                        />
                    </Form.Group> */}
                
                    {/* Message */}
                    {/* <Form.Group className="mb-3" controlId={"formBasicMessage"}>
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                            //TODO: Make textarea
                            name="message"
                            data-testid="message-input"
                            value={this.state.messageInput}
                            className={`form-control`}
                            onChange={(e) => this.setMessage(e)}
                        />
                    </Form.Group> */}
                </Form>

                {this.printDonateButtons()}
            </div>
        );
    }
}

/**
 * Returns the flag emoji corresponding to the given country code.
 * @param {string} countryCode - The country code.
 * @returns {string} The flag emoji.
 */
function GetFlagEmoji(countryCode)
{
    let flagIndicator = 0x1f1a5; //Regional Indicator Symbol Letter A
    return String.fromCodePoint(...[...countryCode.toUpperCase()].map(x => flagIndicator + x.charCodeAt()))
}

export default Donate;
