/**
 * This file defines the BirthdayField component.
 * It is used to input a user's birthday.
 */

import React, {Component} from 'react';
import {Card, Form} from "react-bootstrap";
import DatePicker from "react-datepicker";

import {RequiredTooltip, CalculateAge, GetDefaultMinAge, GetDefaultMaxAge,
        DATE_PICKER_YEAR_RANGE} from '../Util';


export class BirthdayField extends Component
{
    /**
     * Represents the BirthdayField component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {string} props.birthday - The default birthday input value.
     * @param {string} props.rationaleText - The rationale text to display.
     * @param {boolean} props.shouldShowChangeWarning - Whether a warning should be shown if the content of the field is changed.
     * @param {Function} props.setParentBirthday - The function to set the parent's birthday input.
     * @param {Function} props.isErrorMessage - The function to check if there is a specific error message.
     * @param {Object} props.locationDetails - The location details for the current community.
     * @param {string} props.idMod - The string to modify the id of the birthday input.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            originalInput: props.birthday,
            birthdayInput: props.birthday,
            rationaleText: props.rationaleText,
            shouldShowChangeWarning: props.shouldShowChangeWarning || false,
            locationDetails: props.locationDetails,
            idMod: props.idMod || "",
        }

        this.setParentBirthday = props.setParentBirthday;
        this.isErrorMessage = props.isErrorMessage;
    }

    /**
     * Determines whether the rationale above the input field should be shown.
     * @returns {boolean} Whether the rationale should be shown.
     */
    shouldShowRationale()
    {
        if (GetDefaultMinAge(this.state.locationDetails) !== 0
        || GetDefaultMaxAge(this.state.locationDetails) !== 0)
            return true; //The rationale shown is the age range at least

        return this.state.rationaleText
            && this.state.rationaleText !== "";
    }

    /**
     * Determines whether the user's age is outside the default age range for the community.
     * @param {number} minAge - The minimum age for the community.
     * @param {number} maxAge - The maximum age for the community.
     * @returns {boolean} Whether the user's age is outside the default age range.
     */
    isAgeRangeWarning(minAge, maxAge)
    {
        if (!this.state.birthdayInput)
            return false;

        let age = CalculateAge(this.state.birthdayInput);
        return (minAge !== 0 && age < minAge)
            || (maxAge !== 0 && age > maxAge);
    }

    /**
     * Sets the birthday and also updates the parent component.
     * @param {string} birthday - The birthday to set.
     */
    setBirthday(birthday)
    {
        this.setState({birthdayInput: birthday});
        this.setParentBirthday(birthday);
    }

    /**
     * Renders the BirthdayField component.
     * @returns {JSX.Element} The rendered BirthdayField component.
     */
    render()
    {
        const required = RequiredTooltip();
        let ageRangeStr;
    
        //Print a string notifying the user of the age range for the community
        let minAge = GetDefaultMinAge(this.state.locationDetails);
        let maxAge = GetDefaultMaxAge(this.state.locationDetails);
        if (minAge !== 0 || maxAge !== 0)
        {
            let ageRangeEnd;
            if (minAge !== 0 && maxAge !== 0)
                ageRangeEnd = <><b>{minAge}</b> - <b>{maxAge}</b>.</>;
            else if (minAge !== 0)
                ageRangeEnd = <>from at least <b>{minAge}</b>.</>;
            else
                ageRangeEnd = <>to at most <b>{maxAge}</b>.</>;

            ageRangeStr = <>The default age range for the {this.state.locationDetails.community} community is {ageRangeEnd}</>;
        }

        return (
            <Form.Group className="mb-3" controlId={"formBirthdayInput" + this.state.idMod}>
                {/* Title */}
                <Form.Label className={this.shouldShowRationale() ? "mb-0" : "mb-1"}>
                    Date of Birth{required}
                </Form.Label>

                {/* Rationale */}
                {
                    this.shouldShowRationale() &&
                        <div className="mb-1">
                            <Form.Text className="text-muted">
                                {
                                    this.state.rationaleText &&
                                        this.state.rationaleText
                                }
                                {
                                    //Add a link break if both strings are present
                                    this.state.rationaleText && ageRangeStr &&
                                        <br/>
                                }
                                {
                                    ageRangeStr &&
                                        ageRangeStr
                                }
                            </Form.Text>
                        </div>
                }

                {/* Input Field */}
                <span data-testid={"birthday-input" + this.state.idMod}>
                    <DatePicker
                        className="react-datepicker"
                        dateFormat={"MMMM d, yyyy" /* Show readable date */}
                        selected={this.state.birthdayInput !== "" ? new Date(this.state.birthdayInput) : null}
                        renderCustomHeader={RenderDatePickerHeader}
                        minDate={new Date(`${new Date().getFullYear() - DATE_PICKER_YEAR_RANGE}-01-01`) /* Start n years behind */}
                        maxDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())}
                        showDisabledMonthNavigation
                        onChange={(date) =>
                        {
                            //Format to YYYY-MM-DD
                            const ymd = date.getFullYear() + "-"
                                    + (date.getMonth() + 1).toString().padStart(2, '0') + "-"
                                    + date.getDate().toString().padStart(2, '0');
                            this.setBirthday(ymd);
                        }}/>
                </span>

                {/* Warning Card */}
                <div className="mb-1 mt-2">
                {
                    this.isAgeRangeWarning(minAge, maxAge) &&
                        <Card bg="warning">
                            <Card.Body>
                                You are outside the age range of the {this.state.locationDetails.community} community!
                            </Card.Body>
                        </Card>
                }
                </div>

                {/* Edit Warning */}
                <div className="mb-1 mt-2">
                {
                    this.state.shouldShowChangeWarning && this.state.birthdayInput !== this.state.originalInput &&
                        <Card bg="warning">
                            <Card.Body>
                                Be aware, changing your birthday will require reapprovals in all communities where age is a factor!
                            </Card.Body>
                        </Card>
                }
                </div>
            </Form.Group>
        );
    }
}

/**
 * Renders the date picker header component.
 *
 * @param {Object} props - The component props.
 * @param {Date} props.date - The selected date.
 * @param {Function} props.changeYear - The function to change the selected year.
 * @param {Function} props.changeMonth - The function to change the selected month.
 * @param {Function} props.decreaseMonth - The function to decrease the selected month.
 * @param {Function} props.increaseMonth - The function to increase the selected month.
 * @param {boolean} props.prevMonthButtonDisabled - Indicates if the previous month button is disabled.
 * @param {boolean} props.nextMonthButtonDisabled - Indicates if the next month button is disabled.
 * @returns {JSX.Element} The rendered date picker header component.
 */
export function RenderDatePickerHeader({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
})
{
    //Range of past n years
    const years = Array.from({ length: DATE_PICKER_YEAR_RANGE }, (_, i) => new Date().getFullYear() + 1 - i);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    return (
        <div className="date-picker">
            {/* Prev Month Button */}
            <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg"><polyline points="15 18 9 12 15 6"></polyline></svg>
            </button>

            {/* Year Selection */}
            <select
                className="form-select year-select"
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(value)}
            >
                {years.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>

            { /* Month Selection */ }
            <select
                className="form-select month-select"
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                }
            >
                {months.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>

            {/* Next Month Button */}
            <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg"><polyline points="9 18 15 12 9 6"></polyline></svg>
            </button>
        </div>
    );
}

export default BirthdayField;
