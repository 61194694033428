/**
 * This file defines the LocationDetails component.
 * It is used for the maintainer to modify the location details for a community.
 */

import React, {Component} from 'react';
import {Accordion, Button, Card, Form} from 'react-bootstrap';
import TimePicker from 'react-time-picker'; //Must be v5.2.0 or else it breaks
import {Dropdown} from 'semantic-ui-react';
import Swal from 'sweetalert2'; //Pop-Up
import withReactContent from 'sweetalert2-react-content';
import _ from 'lodash';

import {AddressField} from '../AddressField';
import {GetReasonableLunchTimes, GetReasonableDinnerTimes} from '../MealTimeField';
import {ViewPasswordToggle} from '../PasswordField';
import {Capitalize, FormatStudentNameList, SendFormToServer, ErrorPopUp,
        CUSTOM_POPUP_BUTTON_CLASSES_SUCCESS, GLOBAL_ERROR_MESSAGES} from '../../Util';

import "../../styles/LocationDetails.css";

const ReactPopUp = withReactContent(Swal);

const UNIMPLEMENTED_FEATURES = new Set(["PoliticalLeaning", "GuestAgeRequest", "GuestGenderRequest"]); //Note PoliticalLeaning is implemented but not used

const FEATURE_EXPLANATIONS = {
    MealApproval: "Meal allocations must be approved before details are sent out.",
    GuestApproval: "New {GUEST_TYPE}s must be approved before joining a meal.",
    HostApproval: "New hosts must be approved before hosting a meal.",
    NewDateAddedMessage: "A message is sent to hosts when new dates are added.",
    UnallocatedMessage: "A message is sent to hosts when there are still unallocated Shabbat {GUEST_TYPE}s on Wednesday.",
    Distance: "Distance between hosts and {GUEST_TYPE}s is considered when allocating {GUEST_TYPE}s.",
    PoliticalLeaning: "Opposite political leanings between hosts and {GUEST_TYPE}s are avoided when allocating {GUEST_TYPE}s.",
    GuestGenderRequest: "{GUEST_TYPE}s can request to not be placed with other {GUEST_TYPE}s of the opposite gender.",
    HostGenderRequest: "Hosts can choose to only host {GUEST_TYPE}s of a specific gender.",
    GuestAgeRequest: "{GUEST_TYPE}s can request to not be placed with {GUEST_TYPE}s outside of a specific age range.",
    HostAgeRequest: "Hosts can choose to only host {GUEST_TYPE}s within a specific age range.",
    GuestFriendRequest: "How {GUEST_TYPE}s can request to be placed with their friends. Note that \"By Name\" will expose the names of the {GUEST_TYPE}s to everyone.",
    HostGuestRequest: "Hosts can request specific {GUEST_TYPE}s. Note this will expose the names of the {GUEST_TYPE}s to everyone.",
    MinGuestAge: "The minimum age of {GUEST_TYPE}s allowed in the community.",
    MaxGuestAge: "The maximum age of {GUEST_TYPE}s allowed in the community.",
};

const ALT_DETAILS_TITLES =
{
    emailAddress: "Shlabot Email",
    fallbackHostDinnerTime: "Fallback Dinner Time",
    fallbackHostLunchTime: "Fallback Lunch Time",
    maintainerEmail: "Maintainer",
    maintainerPassword: "Password",
};

const ALT_FEATURE_TITLES =
{
    //MealApproval
    GuestApproval: "{GUEST_TYPE} Approval",
    //HostApproval
    NewDateAddedMessage: "New Date Messages",
    UnallocatedMessage: "Unallocated Messages",
    Distance: "Distance Checking",
    //PoliticalLeaning
    GuestGenderRequest: "{GUEST_TYPE} Gender Request",
    //HostGenderRequest
    GuestAgeRequest: "{GUEST_TYPE} Age Request",
    //HostAgeRequest
    GuestFriendRequest: "{GUEST_TYPE} Friend Request",
    HostGuestRequest: "Host {GUEST_TYPE} Request",
    MinGuestAge: "Minimum {GUEST_TYPE} Age",
    MaxGuestAge: "Maximum {GUEST_TYPE} Age",
};

const DROPDOWN_OPTIONS =
{
    guestType: ["guest", "student"].map(
        (type, i) => ({key: i, text: Capitalize(type), value: type})
    ),
    GuestFriendRequest: [{key: 0, text: "Off", value: "off"}].concat(
        ["name", "email"].map(
            (type, i) => ({key: i + 1, text: `By ${type}`, value: type}    
        )
    )),
};

const FEATURE_ERRORS =
{
    MinGuestAge: new Set(["INVALID_MIN_GUEST_AGE", "MAX_AGE_LESS_THAN_MIN_AGE"]),
    MaxGuestAge: new Set(["INVALID_MAX_GUEST_AGE", "MAX_AGE_LESS_THAN_MIN_AGE"]),
}

const ERROR_MESSAGES =
{
    ...GLOBAL_ERROR_MESSAGES,
    INVALID_CITY: "Invalid city name!",
    INVALID_COUNTRY: "Invalid country name!",
    INVALID_COUNTRYCODE: "Invalid country code!",
    INVALID_TIMEZONE: "Invalid timezone!",
    INVALID_ADDRESS: "Invalid community address!",
    INVALID_COORDS: "Invalid community address coordinates! Choose a new location on the map.",
    INVALID_GUESTTYPE: "Invalid guest type!",
    INVALID_EMAILADDRESS: "Invalid community email address!",
    INVALID_MAINTAINEREMAIL: "No person was found with this maintainer email address!",
    INVALID_MAINTAINERPASSWORD: "Invalid maintainer password!",
    INVALID_FALLBACKHOST: "No host was found with this fallback host email address!",
    INVALID_FALLBACKHOSTDINNERTIME: "Invalid fallback host dinner time!",
    INVALID_FALLBACKHOSTLUNCHTIME: "Invalid fallback host lunch time!",
    INVALID_FEATURES: "Invalid feature list!",
    INVALID_MIN_GUEST_AGE: "Invalid minimum guest age! Must be a reasonable non-negative number.",
    INVALID_MAX_GUEST_AGE: "Invalid maximum guest age! Must be a non-negative number.",
    MAX_AGE_LESS_THAN_MIN_AGE: "The maximum guest age must be greater than or equal to the minimum guest age!",
}

const INPUT_FIELD_GROUP_CLASS = "location-details-input-field-group";
const INPUT_FIELD_CLASS = "location-details-input-field";

const HIGHEST_MIN_GUEST_AGE = 120; //The highest reasonable minimum guest age


export class LocationDetails extends Component
{
    /**
     * Represents the LocationDetails component.
     * @constructor
     * @param {Object} props - The props object containing the component's properties.
     * @param {Object} props.studentList - The list of students in the community.
     * @param {Object} props.hostList - The list of hosts in the community.
     * @param {Object} props.locationDetails - The location details for the current community.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            showPassword: false,
            originalMaintainerPassword: props.password,
            studentList: props.studentList,
            hostList: props.hostList,
            locationDetails: _.cloneDeep(props.locationDetails), //Deep copy to avoid changing the parent's state
            originalLocationDetails: props.locationDetails,
            errorMsg: "",
        };
    }

    /**
     * Checks if the chosen maintainer is valid.
     * @returns {boolean} Whether the chosen maintainer is valid.
     */ 
    validMaintainer()
    {
        let email = this.state.locationDetails.maintainerEmail;
        return email !== ""
            && (email in this.state.studentList || email in this.state.hostList);
    }

    /**
     * Checks if the chosen fallback host is valid.
     * @returns {boolean} Whether the chosen fallback host is valid.
     */
    validFallbackHost()
    {
        let email = this.state.locationDetails.fallbackHost;
        return email === ""
            || Object.keys(this.state.hostList).length === 0 //In this case just reuse the current email
            || email in this.state.hostList; //Can be empty
    }

    /**
     * Checks if the maintainer password is valid.
     * @returns {boolean} Whether the maintainer password is a valid password to use.
     */
    validPassword()
    {
        return this.state.locationDetails.maintainerPassword !== "";
    }

    /**
     * Checks if the community address is valid.
     * @returns {boolean} Whether the community address is valid.
     */
    validAddress()
    {
        return this.state.locationDetails.address !== ""
            && this.state.locationDetails.coords != null;
    }

    /**
     * Checks if the minimum guest age is a valid numbers.
     * @returns {boolean} Whether the minimum guest age is a valid number.
     */
    validMinGuestAge()
    {
        const minAge = this.state.locationDetails.features.MinGuestAge;
        return minAge >= 0 && minAge <= HIGHEST_MIN_GUEST_AGE;
    }

    /**
     * Checks if the maximum guest age is a valid number.
     * @returns {boolean} Whether the maximum guest age is a valid number.
     */
    validMaxGuestAge()
    {
        return this.state.locationDetails.features.MaxGuestAge >= 0;
    }

    /**
     * Checks if the maximum guest age is less than the minimum guest age.
     * @returns {boolean} Whether the maximum guest age is less than the minimum guest age.
     */
    reversedMinMaxGuestAges()
    {
        let minAge = this.state.locationDetails.features.MinGuestAge;
        let maxAge = this.state.locationDetails.features.MaxGuestAge;
        let noIssue = minAge === 0 || maxAge === 0 || minAge <= maxAge;
        return !noIssue;
    }

    /**
     * Gets the error message (if present) at the time of form submission.
     * @returns {string} The error message symbol.
     */
    getErrorMessage()
    {
        let errorMsg = "";

        if (!this.validMaintainer())
            errorMsg = "INVALID_MAINTAINEREMAIL";
        else if (!this.validFallbackHost())
            errorMsg = "INVALID_FALLBACKHOST";
        else if (!this.validPassword())
            errorMsg = "INVALID_MAINTAINERPASSWORD";
        else if (!this.validAddress())
            errorMsg = "INVALID_ADDRESS";
        else if (!this.validMinGuestAge())
            errorMsg = "INVALID_MIN_GUEST_AGE";
        else if (!this.validMaxGuestAge())
            errorMsg = "INVALID_MAX_GUEST_AGE";
        else if (this.reversedMinMaxGuestAges())
            errorMsg = "MAX_AGE_LESS_THAN_MIN_AGE";

        return errorMsg;
    }

    /**
     * Checks if an error message symbol is the last shown error message.
     * @param {string} errorMsg - The error message symbol to check.
     * @returns {boolean} Whether the error message symbol was last shown.
     */
    isErrorMessage(errorMsg)
    {
        return this.state.errorMsg === errorMsg;
    }

    /**
     * Handles a change to one of the location details fields.
     * @param {string} key - The field to change.
     * @param {string} value - The new value for the field.
     */
    onChangeField(key, value)
    {
        const locationDetails = this.state.locationDetails;
        locationDetails[key] = (value != null) ? value : "";
        this.setState({locationDetails: locationDetails});
    }

    /**
     * Handles a change to one of the features.
     * @param {Event} e - The onChange event.
     * @param {string} featureKey - The key of the feature.
     * @param {boolean} isBool - Whether the feature is a checkbox field.
     * @param {boolean} isNumber - Whether the feature is a number input field.
     */
    onChangeFeatureField(e, featureKey, isBool, isNumber)
    {
        const locationDetails = this.state.locationDetails;
        let value = (isBool) ? e.target.checked //Checkbox
                  : (isNumber) ? Math.max(0, parseInt(e.target.value)) //Number input
                  : e.target.value; //Text input
        locationDetails.features[featureKey] = value;
        this.setState({locationDetails: locationDetails});
    }

    /**
     * Sets the default address for the community.
     * @param {string} address - The address of the community.
     * @param {Object} coords - The coordinates of the address selected.
     */
    setAddressDetails(address, coords)
    {
        const locationDetails = this.state.locationDetails;
        locationDetails.address = address;
        locationDetails.coords = coords;
        this.setState({locationDetails: locationDetails});
    }

    /**
     * Wipes any changes made to the location details.
     */
    undoChanges()
    {
        this.setState({locationDetails: _.cloneDeep(this.state.originalLocationDetails)});
    }

    /**
     * Sends the updated location details to the server and reloads the page data.
     */
    async saveChanges()
    {
        //Verify there are no errors before saving
        let errorMsg = this.getErrorMessage();
        this.setState({errorMsg: errorMsg});
        if (errorMsg !== "")
        {
            this.errorPopUp(errorMsg);
            return;
        }

        //Show a pop-up confirming the approval
        let {isConfirmed} = await ReactPopUp.fire
        ({
            title: `Save the changes to ${this.state.locationDetails.community}'s details?`,
            showCancelButton: true,
            confirmButtonText: `Yes`,
            cancelButtonText: `No`,
            buttonsStyling: false,
            customClass: CUSTOM_POPUP_BUTTON_CLASSES_SUCCESS,
        });

        if (!isConfirmed)
            return;

        //Send the data to the server
        const data =
        {
            ...this.state.locationDetails,
            password: this.state.originalMaintainerPassword,
        };

        let success = await SendFormToServer(data, this, `/maintainerupdatelocationdetails`, `Details updated successfully!`,
                                             this.state.locationDetails, null);

        //Reload the page data after saving
        if (success)
            window.location.reload();
    }
    
    /**
     * Displays an error pop-up.
     * @param {string} errorSymbol - The error symbol for the message to be shown on the pop-up.
     */
    errorPopUp(errorSymbol)
    {
        let text = (errorSymbol in ERROR_MESSAGES) ?  ERROR_MESSAGES[errorSymbol] : errorSymbol;
        ErrorPopUp(text);
    }

    /**
     * Prints the label for one of the location details fields.
     * @param {Object} keyNames - A map converting location details keys to readable text.
     * @param {string} key - The location detail key to get the label for.
     * @returns {JSX.Element} - The form label for field.
     */
    printFieldLabel(keyNames, key)
    {
        //Get the proper text for the field name
        let text;
        if (key in ALT_DETAILS_TITLES)
            text = ALT_DETAILS_TITLES[key];
        else if (key in ALT_FEATURE_TITLES)
            text = ALT_FEATURE_TITLES[key];
        else if (key in keyNames)
            text = keyNames[key];
        else
            text = key.split(/(?=[A-Z])/).join(" "); //Split on capital letters
        text = Capitalize(text);
        text = text.replaceAll("{GUEST_TYPE}", Capitalize(this.state.locationDetails.guestType))

        //Return the label
        return (
            <Form.Label className="location-details-input-field-label">
                {text}
            </Form.Label>
        );
    }

    /**
     * Prints the immutable location details fields.
     * @param {Object} keyNames - A map converting location details keys to readable text.
     * @returns {JSX.Element} The immutable location details fields.
     */
    printReadOnlyFields(keyNames)
    {
        const readOnlyKeys = ["city", "country", "timezone", "countryCode", "emailAddress"];
        const locationDetails = this.state.locationDetails;

        //Print each field
        return readOnlyKeys.map((key, i) =>
        {
            let className = INPUT_FIELD_CLASS;
            if (this.isErrorMessage(`INVALID_${key.toUpperCase()}`))
                className += " is-invalid";

            return (
                <Form.Group key={i} className={INPUT_FIELD_GROUP_CLASS}>
                    {this.printFieldLabel(keyNames, key)}
                    <Form.Control className={className}
                                  type="text" value={locationDetails[key]}
                                  readOnly disabled />
                </Form.Group>
            );
        });
    }

    /**
     * Prints the dropdown fields for selecting students or hosts.
     * @param {Object} keyNames - A map converting location details keys to readable text.
     * @returns {JSX.Element} The dropdown fields for maintainer and fallback host.
     */
    printPersonDropdowns(keyNames)
    {
        const personKeys = ["maintainerEmail", "fallbackHost"];

        //Combine the student and host lists
        let hostList = {};
        for (const [key, value] of Object.entries(this.state.hostList))
            hostList[key] = {...value, name: `${value.name} (${value.email})`}; //Add an email to each host's name
        let combinedList = {...hostList};
        for (const [key, value] of Object.entries(this.state.studentList))
            combinedList[key] = {...value, name: `${value.firstName} ${value.lastName} (${value.email})`}; //Make sure each student has a name field
    
        //Create the name lists for the dropdowns
        const hostNameList = FormatStudentNameList(Object.values(hostList));
        const combinedNameList = FormatStudentNameList(Object.values(combinedList));

        //Set the dropdown options and onChange functions
        const personLists =
        {
            maintainerEmail: (combinedNameList.length === 0) ? [{key: 0, text: this.state.locationDetails.maintainerEmail, value: ""}] //Just show the current email if no students or hosts
                            : combinedNameList, //Maintainer can be either student or host
            fallbackHost: (hostNameList.length === 0) ? [{key: 0, text: this.state.locationDetails.fallbackHost, value: ""}] //Just show the current email if no hosts
                           : [{key: -1, text: "None", value: ""}].concat(hostNameList), //Fallback host can obviously only be host, but not required
        }
        const minOptions = //The number of options more of are needed in order to display a dropdown
        {
            maintainerEmail: 0,
            fallbackHost: 1,
        }
        const onChangeFuncs =
        {
            maintainerEmail: this.onChangeField.bind(this, "maintainerEmail"),
            fallbackHost: this.onChangeField.bind(this, "fallbackHost"),
        };

        //Print each field
        return personKeys.map((key, i) =>
        {
            let className = INPUT_FIELD_CLASS;
            if (this.isErrorMessage(`INVALID_${key.toUpperCase()}`))
                className += " is-invalid";

            if (personLists[key].length <= minOptions[key]) //Only one option, so just display a read-only field
            {
                return (
                    <Form.Group key={i} className={INPUT_FIELD_GROUP_CLASS}>
                        {this.printFieldLabel(keyNames, key)}
                        <Form.Control className={className}
                                      type="text" value={personLists[key][0].text}
                                      readOnly disabled />
                    </Form.Group>
                );
            }

            //Display a dropdown for the field
            return (
                <Form.Group key={i}>
                    <div className={INPUT_FIELD_GROUP_CLASS}>
                        {this.printFieldLabel(keyNames, key)}
                        <Dropdown
                            className={className}
                            fluid
                            search
                            selection
                            options={personLists[key]}
                            value={this.state.locationDetails[key]}
                            onChange={(_, data) => onChangeFuncs[key](data.value)} />
                    </div>
                </Form.Group>
            );
        });
    }

    /**
     * Prints the mutable location details fields.
     * @param {Object} keyNames - A map converting location details keys to readable text.
     * @returns {JSX.Element} The mutable location details fields.
     */
    printEditableFields(keyNames)
    {
        const editableKeys = ["fallbackHostDinnerTime", "fallbackHostLunchTime", "guestType", "maintainerPassword"];
        const locationDetails = this.state.locationDetails;
        const minMaxMealTimes = {
            fallbackHostDinnerTime: GetReasonableDinnerTimes(),
            fallbackHostLunchTime: GetReasonableLunchTimes(),
        };

        //Print the fields
        return editableKeys.map((key, i) =>
        {
            const onChange = (e) => {this.onChangeField(key, e.target.value)}; //Default onChange func
            let className = INPUT_FIELD_CLASS;
            if (this.isErrorMessage(`INVALID_${key.toUpperCase()}`))
                className += " is-invalid";

            return (
                <Form.Group key={i} className={INPUT_FIELD_GROUP_CLASS}>
                    {this.printFieldLabel(keyNames, key)}
                {
                    (key in DROPDOWN_OPTIONS) ?
                        //Print a dropdown for the field
                        <Dropdown
                            className={className}
                            fluid
                            search
                            selection
                            options={DROPDOWN_OPTIONS[key]}
                            value={locationDetails[key]}
                            onChange={(e, data) => this.onChangeField(key, data.value)} />
                    : (key.endsWith("Time")) ?
                        //Print a time input for the field
                        <TimePicker
                            className={"form-control " + className}
                            minTime={minMaxMealTimes[key][0]}
                            maxTime={minMaxMealTimes[key][1]}
                            hourPlaceholder="HH"
                            minutePlaceholder="MM"
                            format="HH:mm" //Use 24 hour clock since AM/PM selector causes issues
                            disableClock={true}
                            clearIcon={null}
                            value={locationDetails[key]}
                            onChange={(time) => this.onChangeField(key, time)} />
                    : (key.endsWith("Password")) ?
                        //Print a password input for the field
                        <div className={"d-flex " + INPUT_FIELD_CLASS}>
                            <Form.Control type={(this.state.showPassword) ? "text" : "password"}
                                          className={className.replaceAll(INPUT_FIELD_CLASS, "")}
                                          value={locationDetails[key]}
                                          onChange={onChange} />
                            <ViewPasswordToggle showPassword={this.state.showPassword}
                                                toggleView={() => this.setState({showPassword: !this.state.showPassword})} />
                        </div>
                    :
                        //Print a text input for the field
                        <Form.Control className={className}
                                        type="text" value={locationDetails[key]}
                                        onChange={onChange} />
                }
                </Form.Group>
            );
        });
    }

    /**
     * Prints the community address selection map.
     * @returns {JSX.Element} The address selection map.
     */
    printAddressSelection()
    {
        const locationDetails = this.state.locationDetails;

        return (
            <AddressField
                addressLine1={locationDetails.address}
                coords={locationDetails.coords}
                country={locationDetails.country}
                defaultCenter={this.state.originalLocationDetails.coords}
                fieldDesc={`The default address for the ${locationDetails.community} community.`}
                required={false} //Don't show the required tooltip
                setParentAddress={this.setAddressDetails.bind(this)}
                isErrorMessage={(error) => this.isErrorMessage(error)}
            />
        );
    }

    /**
     * Prints the mutable feature fields.
     * @returns {JSX.Element} The mutable feature fields.
     */
    printFeatures()
    {
        const locationDetails = this.state.locationDetails;

        //Get the features to display
        let featuresKeys = Object.keys(locationDetails.features);
        featuresKeys = featuresKeys.map(key => [key, key.split(/(?=[A-Z])/).join(" ")]); //Split on capital letters
        featuresKeys = Object.fromEntries(featuresKeys.map(key => [key[0], key[1]])); //Turn into objects
        featuresKeys = Object.fromEntries(Object.entries(featuresKeys).sort(
            (a, b) => typeof(locationDetails.features[a[0]]) === "boolean" ? -1 : 1) //Sort the ones with bools to the top
        );

        //Print the fields
        return (
        <>
            <h3 className="location-details-features-label">Features</h3>
            <Form.Text className="text-center">Select all that apply to the {locationDetails.community} community</Form.Text>
            <div className="location-details-features">
            {
                Object.keys(featuresKeys).map((key, i) =>
                {
                    return this.printFeatureField(key, featuresKeys);
                })
            }
            </div>
        </>
        );
    }

    /**
     * Prints a single feature field.
     * @param {string} featureKey - The key of the feature.
     * @param {Object} featuresKeys - A map converting feature keys to readable text.
     * @returns {JSX.Element} The feature field.
     */
    printFeatureField(featureKey, featuresKeys)
    {
        const locationDetails = this.state.locationDetails;
        const isBool   = typeof(locationDetails.features[featureKey]) === "boolean";
        const isNumber = typeof(locationDetails.features[featureKey]) === "number";
        const selectedBgColour = "success";
        const unselectedBgColour = "light";
        const bgColour = (isBool)   ? (locationDetails.features[featureKey]     ? selectedBgColour : unselectedBgColour)
                       : (isNumber) ? (locationDetails.features[featureKey] > 0 ? selectedBgColour : unselectedBgColour) //0 means disabled
                       : (locationDetails.features[featureKey] !== "" && locationDetails.features[featureKey] !== "off") ? selectedBgColour
                       : unselectedBgColour; //Change the colour of the card whether the feature is enabled or disabled
        const textColour = (bgColour === unselectedBgColour) ? "dark" : "white";
        let inputClassName = "mb-3"
        const onChange = (e) => this.onChangeFeatureField(e, featureKey, isBool, isNumber);

        //Check if feature is not implemented yet
        if (UNIMPLEMENTED_FEATURES.has(featureKey))
            return null;

        //Check if the feature field is invalid
        if (featureKey in FEATURE_ERRORS)
        {
            for (const error of FEATURE_ERRORS[featureKey])
            {
                if (this.isErrorMessage(error))
                {
                    inputClassName += " is-invalid";
                    break;
                }
            }
        }

        //Print feature
        return (
            <Card key={featureKey} bg={bgColour} text={textColour}
                  className="location-details-feature"
                  style={{cursor: (isBool) ? "pointer" : "default"}}
                  onClick={(isBool) ? this.onChangeFeatureField.bind(this, 
                                        {target: {checked: !locationDetails.features[featureKey]}}, featureKey, isBool, isNumber) //Allow clicking anywhere on the feature
                                    : null} >
                <Card.Header>{this.printFieldLabel(featuresKeys, featureKey)}</Card.Header>
                <Card.Body>
                {
                    isNumber ? //Show an input box number fields
                        <Form.Control className={inputClassName}
                                    type="number"
                                    value={locationDetails.features[featureKey]}
                                    onChange={onChange} />
                    : !isBool ? //Show a dropdown for text fields
                        <Dropdown
                            className={inputClassName}
                            fluid
                            search
                            selection
                            options={DROPDOWN_OPTIONS[featureKey]}
                            value={locationDetails.features[featureKey]}
                            onChange={(e, data) => this.onChangeFeatureField({target: {value: data.value}}, featureKey, isBool, isNumber)} />
                    : null //Show nothing for boolean fields
                }
                {this.printFeatureExplanation(featureKey)}
                </Card.Body>
            </Card>
        );
    }

    /**
     * Prints the explanation for a feature.
     * @param {string} featureKey - The key of the feature.
     * @returns {JSX.Element|null} The explanation for the feature.
     */
    printFeatureExplanation(featureKey)
    {
        //Get the explanation
        let explanation = FEATURE_EXPLANATIONS[featureKey];
        if (!explanation)
            return null;

        //Replace placeholders
        explanation = explanation.replaceAll("{GUEST_TYPE}", this.state.locationDetails.guestType);
        explanation = Capitalize(explanation); //In case it started with {GUEST_TYPE}

        //Print the explanation
        return (
            <small className="location-details-feature-explanation">
                {explanation}
            </small>
        );
    }

    /**
     * Prints the buttons to undo or save the changes.
     * @returns {JSX.Element|null} The buttons to undo or save the changes.
     */
    printButtons()
    {
        const buttonClass = "maintainer-button location-details-button";

        //Only show if the location details have been changed
        if (_.isEqual(this.state.locationDetails, this.state.originalLocationDetails))
            return null;

        //Print the buttons
        return (
            <div className="location-details-buttons">
                <Button variant="danger" className={buttonClass} onClick={this.undoChanges.bind(this)}>Undo Changes</Button>
                <Button variant="success" className={buttonClass} onClick={this.saveChanges.bind(this)}>Save Changes</Button>
            </div>
        )
    }

    /**
     * Renders the LocationDetails component.
     * @returns {JSX.Element} The rendered LocationDetails component.
     */
    render()
    {
        const locationDetails = this.state.locationDetails;
        const community = locationDetails.community;

        //Get keys to display
        let keyNames = Object.keys(locationDetails);
        keyNames = keyNames.map(key => [key, key.split(/(?=[A-Z])/).join(" ")]); //Split on capital letters
        keyNames = Object.fromEntries(keyNames.map(key => [key[0], key[1]])); //Turn into objects

        //Print details
        return (
        <>
            <Accordion.Header>{community}{(community.toLowerCase().endsWith("s")) ? "'" : "'s"} Details</Accordion.Header>
            <Accordion.Body className="maintainer-main-accordian-body">
                <Form className="location-details-container">
                    <div className="location-details-fields-container">
                        {/* Read-only Fields */}
                        {this.printReadOnlyFields(keyNames)}

                        {/* Special Dropwdown Fields */}
                        {this.printPersonDropdowns(keyNames)}

                        {/* Editable Fields */}
                        {this.printEditableFields(keyNames)}

                        {/* Print Location Map */}
                        {this.printAddressSelection()}
                    </div>

                    {/* Features */}
                    {this.printFeatures()}
                </Form>

                {/* Undo & Save Buttons */}
                {this.printButtons()}
            </Accordion.Body>
        </>
        );
    }
}

export default LocationDetails;
